.controls {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  background-color: #2A2B2B;
}

.reset {
  cursor: pointer;
}
