@import 'src/styles/utils';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: 80vh;
  overflow-y: auto; 

  &::-webkit-scrollbar {
    width: 6px; 
  }

  &::-webkit-scrollbar-track {
    background-color: transparent; 
  }

  &::-webkit-scrollbar-thumb {
    background-color: #84c47c;
    border-radius: 3px; 
  }
}

.list {
  display: flex;
  flex-flow: row wrap;
  gap: 8px;

  @include breakpoint('md') {
    flex-direction: column;
  }
}

.listCoins {
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  margin-top: 33px;
}

.item {
  display: flex;
  flex-direction: row;
  width: 32%;
  padding: 8px;
  background: #313333;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background: #3f4141;
  }

  @include breakpoint('md') {
    width: 100%;
  }
}

.itemActive {
  background: #7ec17f;

  &:hover {
    background: #7ec17f;
  }
}

.itemImage {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.itemData {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.label {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.01em;
}

.labelActive {
  color: #262626;
}

.value {
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.01em;
  opacity: 0.5;
}

.valueActive {
  color: #262626;
} 

.fiatSection {
  margin-top: 20px;
}
