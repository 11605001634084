.searchContainer {
  position: relative; // Ensure the container is the reference for absolute positioning
  display: flex;
  flex-direction: column; // Ensure the input and results stack vertically
  align-items: center;
  width: 75%; // Make sure it takes the full width of its container
}

.searchInput {
  width: 100%;
  padding: 8px 12px;
  color: #fff;
  font-size: 14px;
  background-color: #2d2d2d;
  border: 1px solid #444;
  border-radius: 4px;
  outline: none;

  &:focus {
    border-color: #7ec17f;
  }
}

.currencyList {
  position: absolute; // This ensures the list appears directly below the input
  top: 100%; // Positions the list right below the search input
  right: 0;
  left: 0;
  z-index: 1000;
  max-height: 300px; // Limit the height of the dropdown
  overflow-y: auto;
  background-color: #1d1d1d;
  border: 1px solid #444;
  border-radius: 4px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #84c47c;
    border-radius: 3px;
  }
}

.currencyItem {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #2b2b2b;
  }
}

.currencyItemActive {
  background-color: #7ec17f;
}

.currencyItemImage {
  margin-right: 8px;
}

.currencyItemData {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.currencyLabel {
  color: #fff;
  font-size: 14px;
}

.currencyLabelActive {
  color: #262626;
}

.currencyValue {
  color: #aaa;
  font-size: 12px;
}

.currencyValueActive {
  color: #262626;
}
