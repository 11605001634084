.shareButton {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  height: 40px;
  padding: .5rem 1rem .5rem .5rem;

  // color: #84C47C;
  background: #2D2D2D;
  cursor: pointer;

  &:hover {
    background-color: #3e4141;
  }

  & .children {
    color: #84C47C;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
}
