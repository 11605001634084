.app {
  display: flex;
  flex-direction: column;
  height: 100%;

   // overflow: hidden; // The reason this one i commented out is because why its active the scroll up button in HowWeRate wont work, 
  // need to find a solution

  @media screen and (max-width: 340px) {
    overflow: hidden;

    // Changed it to very small devices so the layout looks better but the button in HowWeRate wont work when the width is below 340px
  }
 }

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.children {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.scrollInContent {
  // overflow: auto; // The reason this one i commented out is because why its active the scroll up button in HowWeRate wont work,
  // need to find a solution
  @media screen and (max-width: 340px) {
    overflow: auto;

    // Changed it to very small devices so the layout looks better but the button in HowWeRate wont work when the width is below 340px
  }
 }
