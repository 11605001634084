.headerSocial {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background-color: #2a2b2b;

  & .transparentBackground {
    background-color: transparent;
  }

  & .item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    background-color: #2a2b2b;

    &:not(:last-child) {
      margin-right: 2px;
    }

    &:hover {
      background: #373838;
    }
  }

  & .mailIcon {
    display: none;

    @media (max-width: 1024px) {
      display: flex;
    }
  }
}
