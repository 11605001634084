@use 'src/styles/utils' as *;

.blurContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%);

  // backdrop-filter: blur(1px);

  @include breakpoint ('sm') {
    width: 100%;
    height: 100%;
  }
}

.filterModal {
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 1rem;
  background-color: #1D1D1D;

  @include breakpoint ('sm') {
    width: 100%;
    height: 100%;
  }
}

.close {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-between;
  padding: 1rem 2rem;
}

.header {
  display: flex;
  gap: 1rem;
  justify-content: center;
  width: 60%;
  margin: 0 auto;
  padding-bottom: 2rem;
  border-bottom: 1px solid var(--color-primary-lightgreen);
}

.title {
  margin: 0;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: linear-gradient(to bottom right, #7CC07C, #4ABF6B);
  border-radius: 50%;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);

}

.filterContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  
}

.buttonContainer {
  display: flex;
  gap: .5rem;
  height: 40px;
}

.buttonContainer > * {
  color: #000;
  font-size: 16px;
  background-color: var(--color-primary-lightgreen);
  box-shadow: 0 0 10px rgb(0 0 0 / 50%); /* Exempel på en enkel box-shadow */
  cursor: pointer;
  transition: box-shadow 0.3s ease; /* Lägger till en jämn övergångseffekt */
}

.buttonContainer > *:hover {
  box-shadow: 0 0 15px rgb(0 0 0 / 70%); /* Skuggan förändras när muspekaren hovrar över */
}

.confirmButton {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: #000;
  background-color: var(--color-primary-lightgreen);
}
