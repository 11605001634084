$color: #fff;
$height: 40px;

.dropdown {
  position: relative;
  display: inline-block;
  width: auto;
  height: $height;
  color: $color;
  cursor: pointer;
  user-select: none;

  .titleValue {
    color: #fff;
  }

  & .block {
    z-index: 20;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    min-width: 40px;
    height: 100%;
    padding: 0 10px;

    // border-radius: 6px;
    background-color: #2a2b2b;
    transition: background-color 0.2s;

    // width: max-content;

    &.isOpen {
      background-color: #3c3d3d;
    }

    &:hover {
      background-color: rgb(60 61 61 / 70%);
    }
  }

  & .title {
    font-size: 14px;
    line-height: 14px;
    white-space: nowrap;
  }

  & .icon {
    margin-left: 6px;

    &.isOpen {
      transform: rotate(180deg);
    }
  }

  & .list {
    position: absolute;
    z-index: 20;
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    margin-top: 4px;
    padding: 8px 0;
    background: #3c3d3d;
    border-radius: 2px;
    box-shadow: 0 4px 8px rgb(0 0 0 / 25%);
  }
}
