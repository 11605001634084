@import 'src/styles/utils';

.wrapper {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  min-width: fit-content;
  height: 40px;
  padding: 0 10px;
  color: rgb(255 255 255 / 50%);

  // border-radius: 6px;
  background-color: #2a2b2b;
  transition: background-color 0.2s;

  @include breakpoint('sm') {
    display: none;
  }

  &:hover {
    background-color: rgb(60 61 61 / 70%);
    cursor: pointer;
  }

  & .label {
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 14px;
    white-space: nowrap;
  }
}

// .active {
//   :global {
//     .react-select__single-value {
//       color: #84c47c;
//     }
//   }
// }
