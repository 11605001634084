@use 'src/styles/utils' as *;

$header-height: 48px;

.header {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 90;
  display: flex;
  flex: 0 0 auto;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  height: $header-height;
  margin: 0;

  // margin-bottom: 8px;
  padding: 0 8px 0 12px;
  font-weight: 400;
  font-size: 16px;
  background-color: #000;
  transition: top 0.5s;

  @media screen and (min-width: 1025px) {
    display: none;
  }
}

.left {
  display: flex;
  gap: .5rem;
  align-items: center;
  margin: 0;
}

.right {
  display: flex;
  align-items: center;
  height: 100%;
}

.fullScreenHeader {
  position: absolute;
  top: -$header-height;
  width: 100%;

  & .active {
    top: 0;
  }
}

.burger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #000;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.2s;
}

.hamburgerMenu {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.closeMenu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: .6rem 1rem;
  opacity: .4;
}

.avatarContainer {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px 12px 20px;
  background-color: #2D2D2D;
}

.usernameContainer {
  flex: 1;
  margin-left: 12px;
}

.username {
  margin: 0;
  font-size: 14px;
}

.profile {
  margin: 0;
  color: var(--text-weak, rgb(255 255 255 / 40%));
  font-size: 12px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0 1rem 1rem;
}

.footer {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}


.hideButton {
  display: none;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonsModalContainer {
  display: flex;
  gap: .5rem;
  align-items: center;
  justify-content: center;
}

.buttonCurrency {
  display: flex;
  flex: 1;
  gap: 8px;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: var(--color-primary-lightgreen);
  font-size: 14px;
  letter-spacing: -0.01em;
  background: #2D2D2D; // New color
  cursor: pointer;
}

.authContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  height: 40px;
  background-color: var(--color-primary-lightgreen);
}

.buttonAuth {
  height: 40px;
}

.layoutMenuButton {

  @media (max-width: 374px) {
    display: none;
  }
}

.buttonLogout {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  color: var(--color-primary-lightgreen);
  background-color: #2D2D2D;
  cursor: pointer;
}