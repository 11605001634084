.tabDesktop {
  display: flex;
  flex: 1;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 30px;
  max-width: 170px;
  padding: 8px;    
  overflow: hidden;
  background-color: #1D1D1D;
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.3s;

  @media (max-width: 1024px) {
    height: 40px;
  }

  &.active {
    flex: 2;
    padding: 12px;
    background-color: #1d1f1e;
    border-top: 1px solid #FFFFFF26;
    cursor: default;
    opacity: 1;


    @media (max-width: 325px) {
      flex: 1;
    }
  }

  .tabName {
    display: flex;
    width: 100%;
    margin: 0;
    overflow: hidden;
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    white-space: nowrap;
    cursor: pointer;
    transition: width 0.25s;
    
    &.active {
      color: #6EC56E;
    }
  }

  .closerWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 425px) {
      position: static;
      width: 20px;
      height: 40px;
    }
  }

  .closer {
    display: flex;
    width: 20px;
    height: 20px;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    transition: opacity 0.2s;
  }
}

.tabMobile {
  display: flex;
  gap: .5rem;
  padding: .5rem;
  border-bottom: 1px solid var(--border-default, #3B3B3B);
  opacity: 0.5;

  &.active {
    background-color: #1d1f1e;
    cursor: default;
    opacity: 1;
  }

}

.tabMobile:hover {
  cursor: pointer;
}

.iconMobile {
  display: flex;
}

.tabNameMobile {
  display: flex;
  width: 100%;
  margin: 0;
  color: #fff;
  transition: width 0.25s;
  
  &.active {
    color: #6EC56E;
  }
}

