.innerLoader {
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 120;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 30px);
  background-color: #1d1d1e;
  opacity: 1;
  transition: opacity 0.5s;
  transition: opacity 0.5s;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
}

.wrapper {
  position: relative;
  width: 134px;
  height: 134px;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000;
  will-change: content;
  zoom: 1;
}

.gray1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 134px;
  background-color: #d7d7d7;
  animation: color-gray-1 1s steps(1, end) infinite both;
}

.gray2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 80px;
  background-color: #b1b1b1;
  animation: color-gray-2 1s steps(1, end) infinite both;
}

.gray3 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40px;
  height: 40px;
  background-color: #ededed;
  animation: color-gray-3 1s steps(1, end) infinite both;
}

.brand {
  width: 134px;
  height: 30px;
  margin-top: 15px;
  text-align: center;
  background-image: url('/assets/brand.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

@keyframes color-gray-1 {
  0%,
  100% {
    background-color: #d7d7d7;
  }

  33% {
    background-color: #b1b1b1;
  }

  66% {
    background-color: #ededed;
  }
}

@keyframes color-gray-2 {
  0%,
  100% {
    background-color: #ededed;
  }

  33% {
    background-color: #d7d7d7;
  }

  66% {
    background-color: #b1b1b1;
  }
}

@keyframes color-gray-3 {
  0%,
  100% {
    background-color: #b1b1b1;
  }

  33% {
    background-color: #ededed;
  }

  66% {
    background-color: #d7d7d7;
  }
}
