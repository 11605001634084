.customDate {
  min-width: 353px;
  background: #3c3d3d;
  border-radius: 2px;
  padding: 16px 24px;
  box-sizing: border-box;
  cursor: auto;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 16px;
      color: #ffffff;
    }
  }

  .iconClose {
    cursor: pointer;
  }

  .formRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;

    .formCol {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .fieldLabel {
        margin-right: 8px;
        color: rgba(255, 255, 255, 0.5);
      }

      input {
        height: 32px;
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid transparent;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 0 16px;
        line-height: 32px;
        width: 112px;
        font-size: 14px;
        color: #ffffff;

        &:focus {
          border: 1px solid #85be85;
        }
      }
    }
  }

  .actionsRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 16px;

    button {
      margin-left: 12px;
      background-color: #3c3d3d;
      border: 1px solid hsla(0, 0%, 100%, 0.5);
      border-radius: 4px;
      color: hsla(0, 0%, 100%, 0.5);
      cursor: pointer;
      font-size: 14px;
      height: 32px;
      line-height: 14px;
      outline: none;
      padding: 0 16px;
      transition: background-color 0.2s;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:hover {
        background-color: hsla(0, 0%, 100%, 0.05);
      }
    }

    .buttonApply {
      background-color: #85be85;
      border: 1px solid transparent;
      color: #282928;

      &:hover {
        background-color: rgba(133, 190, 132, 0.9);
      }
    }
  }
}
