.btn {
  position: fixed;
  right: 20px;
  bottom: 10px;
  z-index: 9999;
  visibility: hidden;
  cursor: pointer;
  opacity: 0;
  opacity: 0.35;
  transition: visibility 0.3s ease;

  &:hover {
    opacity: 1;
  }
}

.show {
  visibility: visible;
}

.list {
  right: 16px;
  bottom: 34px;
}
