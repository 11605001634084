.coins {
  background: #3e4141;
  display: flex;
  flex-flow: row wrap;
  padding: 10px;
  max-height: 180px;
  overflow-x: hidden;

  & .coin {
    align-items: center;
    background: #5ea155;
    border-radius: 22px;
    display: flex;
    height: 32px;
    margin: 1px 5px 1px 0;
    min-width: 62px;
    padding: 4px 5px;

    & .coinData {
      min-width: 0;
      display: flex;

      .iconContainer {
        margin-right: 8px;
        margin-left: 4px;
        height: 16px;
        max-height: 16px;
        max-width: 16px;
        min-width: 16px;
        position: relative;
        width: 16px;
      }

      & .clickable {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        padding: 0 10px;
        cursor: pointer;
      }
    }
  }
}
