.wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(50 50 52 / 99%);
  background-color: #1D1D1D;
  box-shadow: none;
  -webkit-overflow-scrolling: touch;
}


