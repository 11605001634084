@use 'src/styles/utils' as *;

$header-height: 56px; // Changed from 40 to 48 px as the design

.header {
  position: relative;
  z-index: 90;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: $header-height;
  margin: 0;
  padding-right: 8px;
  font-weight: 400;
  font-size: 16px;
  background-color: #000; // New background color
  transition: top 0.5s;

  @media screen and (max-width: 1150px) {
    gap: 0.5rem;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }

  &.fixed {
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
  }

  &.fullScreenHeader {
    position: absolute;
    top: -$header-height;
    width: 100%;

    & .active {
      top: 0;
    }
  }
}

.left {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  &--widget {
    margin: 0;
    margin-left: 2px;
    color: #fff;
    font-size: 12px;
    line-height: 14px;
  }

  &--isMobile {
    margin: 0;
  }
}

.logoContainer {
  height: 100%;
  padding: 20px 4px;
  cursor: pointer;
}

.logo {
  height: 100%;
  margin: 0 16px;
}

.activeLogo {
  background-color: #1d1d1d;
}

.right {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.buttonContainer {
  display: flex;
  gap: 0.3rem;
  align-items: center;
  justify-content: center;
}

.shareButton {
  display: flex;
  align-items: stretch;
  box-sizing: border-box;
  height: 40px;
}

.tooltip {
  right: 20px;
  left: auto !important;
}

.buttonCurrency {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  min-width: 70px;
  margin: 0 3px;
  padding: 5px;
  color: #84c47c;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  background: #1d1d1d; // New color
  cursor: pointer;
}

.hideButton {
  display: none;
}

.mobileTabs {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 8px;
}

/* Placeholder CSS */
.tabPlaceholder {
  width: 100%; /* This should match the expected width of your tabs */
  height: 48px; /* Adjust the height to match your tabs */
  margin-right: 8px; /* Adjust based on layout */
  border-radius: 4px;
  transition: opacity 0.1s ease-out; /* Smooth transition for the placeholder */
}

/* Additional styles for animating the loading and loaded states */
.tabsLoading {
  opacity: 0;
}

.tabsLoaded {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

