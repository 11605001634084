.item {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 11px;
  color: #dd7875;
  font-weight: normal;
  font-size: 14px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  white-space: nowrap;
  text-transform: capitalize;
  text-decoration: none;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;

  &:hover {
    background: #1d1f1e;
    border-bottom: 2px solid #344035;
    cursor: pointer;
    opacity: 0.8;
  }

  &.isMobile {
    justify-content: flex-start;
    height: 52px;
    padding: 0 21px;
    font-size: 18px;
  }
}

.iframe {
  width: 550px;
  height: 462px;
  background-color: #313333;
  border: none;
  box-shadow: none;

  @media (max-width: 576px) {
    width: 100%;
  }
}
