.footer {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  color: rgb(255 255 255 / 50%);
  line-height: normal;
  background: #2a2b2b;

  &.withPadding {
    padding-bottom: 24px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 5px 16px;

    @media (min-width: 1024px) {
      padding: 0 16px;
    }
  }

  .contentPart {
    margin: 8px 0;

    &.logo {
      display: flex;
      width: 120px;
    }

    &.toTopStack {
      width: 40px;
      height: 40px;
      margin: -1px -10px -1px 0 !important;
    }

    &.contacts {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: calc(100% - 150px);
    }

    &.copyright {
      display: flex;
      align-items: center;
      width: calc(100% - 70px);
    }

    @media (min-width: 768px) {
      &.logo {
        order: 1;
      }

      &.version {
        order: 2;
      }

      &.contacts {
        order: 3;
        width: calc(100% - 216px);
        margin-right: 12px;

        .headerSocial {
          justify-content: flex-end;
        }
      }

      &.toTopStack {
        order: 4;
      }

      &.copyright {
        order: 5;
        width: 100%;
      }
    }

    @media (min-width: 1024px) {
      &.copyright {
        flex: 1;
        order: 3;
        width: auto;
        margin-left: 16px;
      }

      &.contacts {
        display: flex;
        order: 4;
        width: auto;
      }

      &.toTopStack {
        order: 5;
        margin: 0 -10px 0 0 !important;
      }
    }
  }

  .launch {
    display: none;
    @media (min-width: 768px) {
      & {
        display: inline;
      }
    }
  }

  .contact {
    display: none;
    align-items: center;
    margin: 0 24px 0 0;

    a {
      display: inline-block;
      color: #84c47c;
      font-size: 14px;
      text-decoration: none;
    }

    @media (min-width: 1024px) {
      display: flex;
    }
  }

  & .textContainer {
    font-size: 11px;

    @media (min-width: 768px) {
      & {
        font-size: 13px;
      }
    }

    @media (min-width: 1024px) {
      & {
        font-size: 14px;
      }
    }
  }

  & .textVersion {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 16px;
    color: #9e9e9e;
    font-size: 10px;
    background: #1d1f1e;
    border-radius: 2px;

    @media (min-width: 1024px) {
      width: 74px;
      height: 24px;
      margin-top: 0 !important;
      font-size: 12px;
    }
  }
}
