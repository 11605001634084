@import 'src/styles/utils';

.dialogClassName {
  width: 100%;
  margin: 0 !important;
}

.container {
  display: flex;
  flex-direction: column;
  width: 662px;
  height: 630px;
  padding: var(--space-80);
  background: var(--gray-800);
  border-radius: var(--radius-6);

  @include breakpoint('md') {
    width: 100%;
    height: 100%;
    padding: var(--space-40);
  }
}

.header {
  margin-bottom: 20px;
  color: var(--color-white);
  font-weight: 400;
  font-size: var(--text-custom-22);
  line-height: 22px;
  letter-spacing: -0.01em;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include breakpoint('md') {
    padding-top: var(--space-40);
    border-top: solid 1px var(--color-function-danger);
  }
}

.buttonSave {
  color: var(--color-surface-800);
  background: var(--color-function-success);
}

.buttonCancel {
  color: var(--color-white);
  background: var(--gray-600);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 49%;
  height: var(--size-90);
  margin-top: 15px;
  font-weight: 500;
  font-size: var(--text-regular);
  line-height: var(--space-50);
  border-radius: var(--radius-6);
  cursor: pointer;
}
