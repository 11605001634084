.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin: 0 10px;
  padding: 0 6px 0 7px;
  border-bottom: 1px solid #5d5d5d;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.titleBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}

.icon {
  margin-right: 8px;
}

.title {
  overflow: hidden;
  color: #c0c0c0;
  font-size: 1rem;
  line-height: 1.5em;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.underline {
  text-decoration: underline;
}

.symbol {
  overflow: hidden;
  color: #5b5b5b;
  font-size: 1rem;
  line-height: 1.5em;
  white-space: nowrap;
  text-transform: uppercase;
  text-overflow: ellipsis;
}

.rightBox {
  display: flex;
  align-items: center;
}

.star {
  width: 16px;
  height: 16px;
  margin-left: 15px;
  cursor: pointer;
  transition: fill-opacity 0.2s;
  fill-opacity: 20%;
}

.starActive {
  fill-opacity: 100%;

  path {
    stroke: #fff;
  }
}

.starHover:hover path {
  stroke: #fff;
}
