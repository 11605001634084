.dropdown {
  background-color: #2D2D2D;
}

.dropdownToggle {
  position: relative;
  display: flex;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: #2D2D2D;
}

.icon {
  opacity: .8;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
}

.dropdownMenu {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  z-index: 1000;
  width: 220px;
  background-color: black;
}