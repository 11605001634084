.searchBox {
  position: relative;

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.inputBox {
  position: relative;
  z-index: 91;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  background: rgb(29 31 30 / 70%);
  border-bottom: none;
}

.input {
  flex: 1;
  width: 100%;
  height: var(--size-80);
  margin: 0;
  padding: 0 var(--space-15);
  color: var(--color-brand-tertiary);
  font-weight: normal;
  font-size: var(--text-regular);
  text-decoration: none;
  background-color: transparent;
  border: none;
  outline: none;

  &::placeholder {
    color: rgb(255 255 255 / 50%);
    font-size: 14px;
  }
}

.listWrap {
  position: absolute;
  top: 35px;
  right: 0;
  left: 0;
  width: 330px;
  height: 300px;
  background-color: var(--color-surface-700);
  background-color: #1D1D1D; 
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
