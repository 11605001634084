.app {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  font-weight: 400;
  font-size: 1rem;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.isScriptWiget {
    overflow: hidden;

    * {
      box-sizing: content-box;
    }
  }
}

.heatmap {
  overflow: hidden;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

.content {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}


