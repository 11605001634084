.root :global {
  .ShareMapData {
    &.map-paddings {
      padding: 20px 20px 7px 20px;
    }

    &--isMobile:not(.isScriptWidget) {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;

      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-around;
    }

    &__Switcher {
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-radius: 1px;
      border: 1px solid #e0e0e0;
      overflow: hidden;
      margin: 10px;
    }
    &__Switcher-Item {
      padding: 6px 0 6px 0;
      font-size: 1rem;
      line-height: 1rem;
      flex: 1;
      text-align: center;
      color: #e0e0e0;
      cursor: pointer;

      &.selected {
        background-color: #e0e0e0;
        color: #323234;
        cursor: default;
      }
    }

    .MapScreenshot {
      width: 100%;
      height: 100%;

      @media (max-width: 900px) {
        max-height: 350px;
      }
    }

    .SymbolScreenshot {
      width: 100%;
      height: 100%;
      max-height: 450px;

      @media (max-width: 900px) {
        max-height: calc(100vh - 233px);
      }
    }

    &__MobileBottomBox {
      background-color: #3f3e44;
      padding: 0 15px;
      width: 100%;
      flex-shrink: 0;
      box-sizing: border-box;
    }
  }
}

.share {
  margin-top: 16px;
}

.input {
  background: transparent;
  border: none;
  border-bottom: 1px solid #b6b6b6;
  color: #e6e6e6;
  display: block;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1em;
  margin: 0;
  outline: none;
  padding: 0 0 5px 0;
  width: 100%;
}

.linkBox {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.copy {
  width: 18px;
  height: 18px;
  display: inline-block;
  margin: 0 0 0 12px;
  cursor: pointer;
  background-image: url('/assets/icons/icon-copy.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.widgetTextarea {
  flex: 1;
  width: 100%;
  height: 130px;
  resize: none;
  outline: none;
  background-color: transparent;
  color: #e0e0e0;
  font-size: 1rem;
  border: 1px solid #e0e0e0;
  line-height: 1.3em;
  margin: 10px 0;
  padding: 10px 5px 10px 5px;
}

.image {
  margin: var(--space-30) 0;
  max-height: 320px;
  object-fit: scale-down;
  width: 100%;
}
