.skyscraper {
  position: sticky;
  top: 20px;
  margin-top: 20px;
  margin-bottom: 120px;

  > div {
    width: 160px;
    height: 600px;
  }
}

.placement {
  width: 160px;
  height: 600px!important;

  > iframe, > div {
    width: 100%;
    height: 100%;
  }
}
