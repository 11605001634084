.mapHeader {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
  box-sizing: border-box;

  .right {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  @media (max-width: 1024px) {
    .slice,
    .period,
    .base,
    .dot {
      display: none;
    }
  }

  .slice,
  .period,
  .base,
  .time {
    font-size: 1rem;
    line-height: 1em;
    font-weight: 400;
    color: #e6e6e6;
  }
  .dot {
    margin: 0 7px;
  }
}
