.searchBox {
  position: relative;
  display: flex;

  @media screen and (min-width: 1025px) {
    display: none;
  }
}

.search {
  height: 100%;
  margin: 0;
  padding: 0 var(--space-20);
  background-color: transparent;
  border: 0;
}

.inputBox {
  position: relative;
  z-index: 91;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: transparent;
  border-bottom: none;
}

.icon {
  display: block;
  width: 18px;
  height: 18px;
  margin: 0;
  padding: 4px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px;
  border: none;
  outline: none;

  @media (max-width: 330px) {
    margin: 0 0 0 4px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(0 0 0 / 40%);
  background-color: #1D1D1D;
}

.inputWrapper {
  position: relative;
  z-index: 101;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 8px;
  background-color: #000;
  border-bottom: 1px solid rgb(255 255 255 / 20%);

}

.input {
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: var(--space-20) var(--space-30);
  color: #e6e6e6;
  font-size: 1rem;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  line-height: 1.5em;
  text-decoration: none;
  background-color: transparent;
  border: none;
  outline: none;
}

.content {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: #1D1D1D;
}

.listWrap {
  height: 300px;
  background-color: var(--color-surface-800);
}

.listCloseIcon {
  position: absolute;
  bottom: calc(var(--size-50) * -1);
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size-100);
  height: var(--size-100);
  margin-right: calc(var(--size-50) * -1);
  background-color: var(--color-surface-700);
  border-radius: 50%;
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  gap: .5rem;
  align-items: center;
}

.closeContainer {
  display: flex;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.closeIcon {
  opacity: 0.5;
}

.cancel {
  height: 2rem;
  padding: 4px 8px;
  color: #7CC07C;
  background-color: #2D2D2D;
}
