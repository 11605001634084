.wrapper {
  background-color: #2a2b2b;
  border-radius: 2px;
  box-sizing: border-box;
  height: 32px;
  padding: 8px 10px;
  position: relative;
  transition: background-color 0.2s;
  width: auto;
}

.buttonDropdown {
  border-radius: 6px !important;
}
