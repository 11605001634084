.dropdown {
  position: relative;
  display: flex;
}

.betaTag {
  position: relative;
  margin-left: 5px;
  padding: 1px 7px 1px 12px; /* Adjust padding to give space for the arrow shape */
  color: white;
  font-size: 10px;
  white-space: nowrap;
  background-color: #5c8456;
  animation: jump 1s infinite; /* Add this line */
  clip-path: polygon(14px 0, 100% 0%, 100% 100%, 14px 100%, 0 50%);
}

/* Create a slanted effect on the right side */
.betaTag::before {
  position: absolute;
  top: 0;
  right: -10px; /* Adjust this value to change the slant width */
  bottom: 0;
  width: 10px; /* This should be the same as the right value above */
  background: inherit;
  content: '';
  clip-path: polygon(10px 0, 0 50%, 10px 100%);
}

/* Optional: if you want to create a slanted effect on the left side as well */
.betaTag::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -10px; /* Adjust this value to change the slant width */
  width: 10px; /* This should be the same as the left value above */
  background: inherit;
  content: '';
  clip-path: polygon(0 0, 10px 50%, 0 100%);
}

.menuItemContainer {
  display: flex;
  align-items: center;
  width: max-content;
}

.betaText {
  margin-left: 7px;
}

.dropdownParent {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 11px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  opacity: 0.8;

  &:hover {
    color: #84e279;
  }
}

.dropdownParentActive {
  color: #84c47c;
}

.dropdownContent {
  position: absolute;
  top: 35px;
  top: 100%;
  left: 50%;
  z-index: 1;
  display: none;
  width: max-content;
  padding: 4px;
  background: #2a2d2d;
  border: 1px solid #3f4242;
  transform: translateX(-50%);
}

.dropdown:hover .dropdownContent {
  display: flex;
  flex-direction: column;
}

.dropdown:hover .dropdownParent {
  color: #84e279;
}

.dropdownItem {
  padding: 12px 16px;
  color: rgb(255 255 255 / 80%);
  font-weight: 500;
  font-size: 15px;

  &:hover {
    color: #84e279;
    background: rgb(255 255 255 / 7%);
  }
}

.separator {
  height: 1px;
  margin: 4px 0;
  background: #3f4242;
}

@keyframes jump {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }
}
