.bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
}

.rangeFilter {
  display: flex;
  flex-flow: column nowrap;
  margin-top: -8px;
  color: #e6e6e6;

  & .info {
    display: flex;
    align-items: center;
    padding: 0 8px;
    color: rgb(255 255 255 / 50%);
    background-color: #313333;

    span {
      color: #fff;
    }
  }

  & .buttonsWrapper {
    display: flex;
    margin-left: auto;

    & .button {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 55px;
      height: 30px;
      margin-left: 8px;
      color: white;
      font-size: 14px;
      background: #3e4141;
      border: 0;
      border-radius: 2px;
      outline: none;
      cursor: pointer;
      user-select: none;
    }

    & .apply {
      background: #518651;
    }
  }
}

.slider {
  box-sizing: border-box;
  min-width: 300px;
  padding: 8px 20px 16px;
  overflow: hidden;
  cursor: default;

  &.mobile {
    padding: 8px 15px 10px;
  }
}

.label {
  display: block;
  margin-left: 19px;
  color: #f0f0f0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

/* slider styling */

.rcSlider {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 14px;
  margin-top: 24px;
  margin-left: 2px;
  padding: 5px 0;
  border-radius: 6px;
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);

  /* override existed classes */

  [class~='rc-slider-rail'] {
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: rgb(119 119 119);
    border-radius: 6px;
  }

  [class~='rc-slider-track'] {
    position: absolute;
    left: 4px;
    z-index: 1;
    height: 1px;
    background-color: #84c47c;
    border-radius: 6px;
  }

  [class~='rc-slider-step'] {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;

    [class~='rc-slider-dot'] {
      position: absolute;
      bottom: 1px;
      width: 4px;
      height: 4px;
      margin-left: -4px;
      vertical-align: middle;
      background-color: #777;
      border-radius: 2px;
      cursor: pointer;
    }

    [class~='rc-slider-dot-active'] {
      background-color: #84c47c;
      border-color: #84c47c;
    }
  }

  [class~='rc-slider-handle'] {
    position: absolute;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    margin-left: -6px;
    background-color: #84c47c;
    border: solid 1px #84c47c;
    border-radius: 50%;
    outline: none;
    cursor: col-resize;
    touch-action: pan-x;

    &:hover {
      cursor: pointer;
    }
  }

  [class~='rc-slider-mark'] {
    position: absolute;
    top: -18px;
    left: 0;
    width: 100%;
    font-size: 10px;

    [class~='rc-slider-mark-text'] {
      position: absolute;
      display: inline-block;
      color: #666;
      text-align: center;
      text-transform: uppercase;
      vertical-align: middle;
      transform: translateX(0);
      cursor: pointer;

      &:last-child {
        transform: translateX(-75%) !important;
      }

      [class~='rc-slider-mark-text-active'] {
        color: inherit;
      }
    }
  }
}
