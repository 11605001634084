.footer {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
}

.buttonFooter a {
  display: flex;
  justify-content: center;
  width: 52px;
  height: 32px;
  padding: 5px;
  background: #3B3B3B; // For rightsidebar
  background: #2D2D2D; // For hamubrgermenu
  cursor: pointer;
}