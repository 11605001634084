.inputBlock {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background: rgba(34, 35, 35, 0.5);

  input {
    background: transparent;
    border-radius: 2px 2px 0 0;
    border: none;
    margin: 0;
    flex: 1 0 auto;
    height: 40px;
    padding: 8px 24px;
    box-sizing: border-box;
    color: rgba(255, 255, 255, 0.2);
    font-size: 16px;
    outline: none;
  }

  button {
    cursor: pointer;
    outline: none;
    border: 0;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 30px;
    border-radius: 2px;
    font-size: 14px;
    margin-right: 4px;

    &.apply {
      background: #518651;
    }

    &.reset {
      background: #3e4141;
    }
  }
}

.suggestions {
  display: flex;
  flex-wrap: wrap;
  margin: 15px 4px 15px 0;
  height: 168px;
  overflow-x: hidden;

  & > div {
    width: 33%;
  }

  & > div:hover {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.08) 0.54%, rgba(255, 255, 255, 0) 100%);
    .drawerRow {
      & .symbolAction {
        display: block;
      }
    }
  }
}
