@import 'src/styles/utils';

.filters {
  // position: fixed;
  z-index: 12;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; // For now when there are no more content in the filters
  width: 100%;
  height: 40px;
  margin-top: 12px;
  margin-bottom: 0.5rem;
  padding: 0 12px;
  color: #959595;
  font-size: 14px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  background-color: #1d1f1e;

  @include breakpoint('sm') {
    padding: 0 4px;
  }
}

.block {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.menu {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  height: 100%;
}

.filterButtons {
  display: flex;
  gap: 0.5rem;
  height: 100%;
}

.filterButton {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  height: 100%;
  padding: 0 16px;
  background-color: #2a2b2b;
  border-style: none;

  @media (max-width: 385px) {
    display: flex;
  }
}

.buttonText {
  margin: 0;
  color: var(--text-body, rgb(255 255 255 / 90%));
  font-size: 14px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

.resetButton {
  display: flex;
  height: 100%;

  @include breakpoint('sm') {
    display: none;
  }
}

.rigthBar {
  z-index: 2;
  box-shadow: -15px 0 15px 0 #1d1f1e;
}

.flashClassName {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 460px) {
    display: none;
  }
}

.flashIconClassName {
  display: flex;
  margin: 0;
}

.triggerWrapper {
  z-index: 1;
  background-color: #1d1f1e;
}

.trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 4px;
  margin-left: 4px;
  background-color: #313333;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.share {
  display: flex;
  align-items: center;
}

.item {
  display: flex;
  flex-direction: column;
  padding: 5px 8px;
  border-top: 1px solid #2d2d2d;
  border-bottom: 1px solid #2d2d2d;
}

.titleItem a {
  font-weight: 500;
  font-size: 15px;

  &:hover {
    opacity: 0.8;
  }
}

.info {
  display: flex;
  flex-direction: row;
}

.name {
  margin-right: 10px;
  color: #84c47c;
  font-weight: 500;
  font-size: 12px;
}

.latestName {
  margin-right: 10px;
  color: orange;
  font-weight: 500;
  font-size: 12px;
}

.latestName:hover {
  cursor: pointer;
}

.date {
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  opacity: 0.5;
}

.readNow {
  color: #fff;
  font-weight: 200;
  font-size: 12px;
  opacity: 0.7;
}

.readNow:hover {
  cursor: pointer;
}
