.newsFeedGlobals {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;

  .link {
    color: inherit;
    text-decoration: none;
    background-color: inherit;
  }

  .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    margin: 0 4px;

    &:last-child {
      margin-right: 35px;
    }
  }

  .itemLabel {
    color: #fff;
    white-space: nowrap;
  }

  .itemBody {
    width: max-content;
    color: #fff;
    font-size: 16px;
    line-height: 21px;
  }

  .itemChange {
    margin-left: 4px;
  }

  .delimiter {
    &::before {
      margin: 0 4px;
      color: rgb(255 255 255 / 60%);
      content: '|';
    }
  }

  .stable {
    color: rgb(188 178 177 / 100%);
  }

  .decrease {
    color: #dc7474;
  }

  .increase {
    color: #84c47c;
  }
}
