.topLeaderboard {
  position: relative;
  z-index: 1;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  width: 100%;
}

.placement {
  // aspect-ratio: 1340 / 75;
  display: block;
  width: 100%;

  @media (max-width: 1024px) {
    aspect-ratio: 768 / 80;
  }

  > iframe,
  > div {
    width: 100%;
    height: 100%;
  }
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: inline-block;
  width: 15px;
  height: 15px;
  overflow: hidden;
  text-indent: -9999px;
  background: #333236;
  border: none;
  cursor: pointer;
  opacity: 0.6;

  &::after {
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 8px);
    width: 15px;
    height: 15px;
    background-image: url('/assets/icons/icon-close-news.svg');
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.2;
    content: '';
  }

  &:hover::after {
    opacity: 0.4;
  }
}
