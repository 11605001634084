.coinButton {
  margin-right: 10px;

  > iframe, > div {
    width: 100%;
    height: 100%;
  }
}

.headerBtn {
  margin-right: 2px;
}
