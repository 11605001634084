.search{
  position: relative;
  z-index: 91;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 210px;
  padding: 0 var(--space-30);
  background: var(--color-surface-700);
  background-color: #1D1D1D;
  border-bottom: none;

  // border-radius: var(--radius-sm);
}

.input {
  flex: 1;
  width: 100%;
  height: var(--size-80);
  margin: 0;
  padding: 0 var(--space-15);
  color: var(--color-brand-tertiary);
  font-weight: normal;
  font-size: var(--text-regular);
  text-decoration: none;
  background-color: transparent;
  border: none;
  outline: none;
}
