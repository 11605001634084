.rangeFilter {
  min-width: 300px;
  padding: 20px 10px 10px 10px;
  box-sizing: border-box;
  overflow: hidden;
  cursor: default;
  color: #e6e6e6;

  /* hide slider marks */
  &.noMarks {
    [class~='rc-slider-mark-text'] {
      display: none !important;
    }
  }

  .label {
    display: block;
    color: #f0f0f0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-left: 19px;
  }


  & .inputsContainer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  & .controlInput {
    background-color: rgba(255, 255, 255, 0.1);
    transition: background-color 0.2s ease;
    border: 0;
    outline: 0;
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    padding: 8px 8px 7px;
    color: white;
    text-align: center;
    cursor: pointer;
    max-width: 110px;

    &.fullWidth {
      max-width: initial;
      width: 49.5%;
    }

    &:hover:not(:focus):not(:disabled) {
      background-color: rgba(255, 255, 255, 0.2);
    }

    &:focus:not(:disabled) {
      background-color: rgba(255, 255, 255, 0.15);
    }

    &:disabled {
      cursor: default;
    }
  }

  & .delimeterDash {
    margin-right: 4px;
    margin-left: 4px;
  }

  &__Tooltip {
    display: inline-block;
    z-index: 9999;
    background: rgba(80, 80, 80, 0.5);
    padding: 3px 4px 2px;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: white;

    @media screen and (max-width: 1024px) {
      font-size: 16px;
      line-height: 18px;
    }

    &-hidden {
      display: none;
    }
  }
}


.rangeFilterMobile {
  display: flex;
  flex-flow: column nowrap;
  margin-top: -8px;
  color: #e6e6e6;

  .label {
    display: block;
    color: #f0f0f0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-left: 19px;
  }


  & .inputsContainer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  & .controlInput {
    background-color: rgba(255, 255, 255, 0.1);
    transition: background-color 0.2s ease;
    border: 0;
    outline: 0;
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    padding: 8px 8px 7px;
    color: white;
    text-align: center;
    cursor: pointer;
    max-width: 110px;

    &.fullWidth {
      max-width: initial;
      width: 49.5%;
    }

    &:hover:not(:focus):not(:disabled) {
      background-color: rgba(255, 255, 255, 0.2);
    }

    &:focus:not(:disabled) {
      background-color: rgba(255, 255, 255, 0.15);
    }

    &:disabled {
      cursor: default;
    }
  }

  & .delimeterDash {
    margin-right: 4px;
    margin-left: 4px;
  }

  & .info {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 8px 0 8px;
    color: rgba(255, 255, 255, 0.5);

    span {
      color: #fff;
    }
  }
}
/* slider styling */

.slider {
  box-sizing: border-box;
  cursor: default;
  min-width: 300px;
  overflow: hidden;
  padding: 4px 10px;

  &.mobile {
    padding: 0 15px 10px;
  }
}

.rcSlider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  border-radius: 6px;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin-top: 24px;
  margin-left: 2px;
  cursor: pointer;
  width: 100%;

  /* override existed classes */

  [class~='rc-slider-rail'] {
    position: absolute;
    background-color: rgb(119, 119, 119);
    height: 1px;
    border-radius: 6px;
    width: 100%;
  }

  [class~='rc-slider-track'] {
    position: absolute;
    left: 4px;
    height: 1px;
    border-radius: 6px;
    background-color: #84c47c;
    z-index: 1;
  }

  [class~='rc-slider-step'] {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;

    [class~='rc-slider-dot'] {
      position: absolute;
      bottom: 1px;
      margin-left: -4px;
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background-color: #777777;
      cursor: pointer;
      vertical-align: middle;
    }

    [class~='rc-slider-dot-active'] {
      background-color: #84c47c;
      border-color: #84c47c;
    }
  }

  [class~='rc-slider-handle'] {
    position: absolute;
    margin-top: -6px;
    margin-left: -6px;
    width: 12px;
    height: 12px;
    cursor: col-resize;
    border-radius: 50%;
    border: solid 1px #84c47c;
    background-color: #84c47c;
    touch-action: pan-x;
    outline: none;

    &:hover {
      cursor: pointer;
    }
  }

  [class~='rc-slider-mark'] {
    position: absolute;
    top: -18px;
    left: 0;
    width: 100%;
    font-size: 10px;

    [class~='rc-slider-mark-text'] {
      position: absolute;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      cursor: pointer;
      color: #666;
      text-transform: uppercase;
      transform: translateX(0);

      &:last-child {
        transform: translateX(-75%) !important;
      }

      [class~='rc-slider-mark-text-active'] {
        color: inherit;
      }
    }
  }
}
