.main {
  flex: 1;

  h1,
  h2,
  h3 {
    color: #fff;
  }

  h4 {
    line-height: 1.2;
    font-size: 1.2rem;
    color: #fff;
    margin: 20px 0 15px 0;
  }
}

.about {
  display: flex;
  padding: 0 16px;
  flex: 1;
  background: url('/assets/images/about/aboutbg.jpg') no-repeat;
  background-size: contain;
  background-attachment: fixed;

  @media screen and (max-width: 1024px) {
    background-attachment: initial;
    flex-direction: column;
    padding-top: 40px
  }
}

.menu {
  width: 165px;
  padding: 50px 0 0;
  position: sticky;
  top: 0;
  max-height: 600px;

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.item {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  height: 32px;
  padding: 16px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: hsla(0, 0%, 100%, 0.3);
    opacity: 0;
    transition: opacity 0.2s;
    width: 3px;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }

  &.active {
    &::before {
      opacity: 1;
      background: #84c47c;
    }

    .link {
      color: #84c47c;
    }
  }
}

.break {
  margin-bottom: 36px;
}

.link {
  line-height: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.4);
  cursor: pointer;

  &:hover {
    color: rgba(255, 255, 255, 0.4);
  }
}
