.errorImage {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c0c0c0;
  font-size: 0.7rem;
  text-align: center;
  background-color: #6f7171;
  border-radius: 50%;
  transition: opacity 400ms;
}

.loaderBox {
  transition: opacity 400ms;

  .loaderItem1,
  .loaderItem2 {
    background-color: #e6e6e6;
    border-radius: 50%;
    opacity: 0.6;
    animation: sk-bounce 2s infinite ease-in-out;
  }

  .loaderItem2 {
    animation-delay: -1s;
  }

  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
    }

    50% {
      transform: scale(1);
    }
  }
}
