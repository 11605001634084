@keyframes loadingF {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.wrapper {
  display: flex;
  padding: 0 70px 0 7px;
  box-sizing: border-box;
  contain: size;
  will-change: transform;
  zoom: 1;
  transition: transform 0.3s;
}

.newsItem {
  font-size: 1em;
  line-height: 1.4em;
  font-weight: 400;
  flex-shrink: 0;
  margin-right: 35px;
  color: #fff;
  will-change: transform;

  .ctNews {
    display: flex;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    text-decoration-color: rgba(255, 255, 255, 1);
    transition: text-decoration-color, 0.8s;
    position: relative;

    &:hover {
      text-decoration: underline;
      text-decoration-color: rgba(255, 255, 255, 1);
    }
  }

  .delimiter {
    width: 1px;
    height: 0.9em;
    margin: auto 5px;
    background-color: #fff;
    opacity: 0.6;
  }

  .author {
    color: #85be85;
    text-transform: capitalize;
  }

  .date {
    color: #85be85;
  }
}

.letterHolder {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  font-size: 0.8em;
  line-height: 1.4em;

  .letter {
    animation-name: loadingF;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    animation-direction: linear;
    color: #fff;
  }

  .l-1 {
    animation-delay: 0.48s;
  }
  .l-2 {
    animation-delay: 0.6s;
  }
  .l-3 {
    animation-delay: 0.72s;
  }
  .l-4 {
    animation-delay: 0.84s;
  }
  .l-5 {
    animation-delay: 0.96s;
  }
  .l-6 {
    animation-delay: 1.08s;
  }
  .l-7 {
    animation-delay: 1.2s;
  }
  .l-8 {
    animation-delay: 1.32s;
  }
  .l-9 {
    animation-delay: 1.44s;
    margin-left: 5px;
  }
  .l-10 {
    animation-delay: 1.56s;
  }
  .l-11 {
    animation-delay: 1.68s;
  }
  .l-12 {
    animation-delay: 1.8s;
  }
  .l-13 {
    animation-delay: 1.92s;
    margin-left: 5px;
  }
  .l-14 {
    animation-delay: 2.04s;
  }
  .l-15 {
    animation-delay: 2.16s;
  }
}
