.container {
  position: absolute;
  top: 35px;
  right: 0;
  left: 0;
  width: 330px;
  height: 300px;
  background-color: var(--color-surface-700);
  background-color: #1D1D1D;
}

.resultContainer {
  box-sizing: border-box;
  width: 330px;
  height: 300px;
  overflow: auto;
  direction: ltr;
  will-change: transform;
}

.resultContainer::-webkit-scrollbar {
  width: 8px;
}

.resultContainer::-webkit-scrollbar-track {
  background: #2D2D2D;
}

.resultContainer::-webkit-scrollbar-thumb {
  background: #6EC56E;
  border-radius: 4px; 
  cursor: pointer;
}
