.social {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 20px 0 0;
}

.socialButton {
  display: flex;
  flex: 1 1 150px;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 4px 20px 6px 15px;
  color: #e6e6e6;
  font-size: 1rem;
  line-height: 1em;
  text-align: center;
  background-color: rgb(255 255 255 / 5%);
  cursor: pointer;
}

.icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 7px 0 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 16px;
  cursor: pointer;
}
