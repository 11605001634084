.stickyCorner {
  position: fixed;
  z-index: 3;
  display: flex;
  pointer-events: none;

  > * {
    pointer-events: auto;
  }

  @media (min-width: 1024px) {
    right: 40px;
    bottom: 40px;
  }

  .close {
    position: absolute;
    top: -8px;
    z-index: 2;
    display: inline-block;
    width: 15px;
    height: 15px;
    overflow: hidden;
    text-indent: -9999px;
    background: #333236;
    border: none;
    cursor: pointer;
    opacity: 0.6;

    &::after {
      position: absolute;
      top: calc(50% - 8px);
      left: calc(50% - 8px);
      width: 15px;
      height: 15px;
      background-image: url('/assets/icons/icon-close-news.svg');
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.2;
      content: '';
    }

    &:hover::after {
      opacity: 0.4;
    }
  }
}

.stickyCornerRight {
  right: 10px;
  bottom: 50px;
  justify-content: flex-end;

  @media (min-width: 1024px) {
    right: 40px;
    bottom: 40px;
  }

  .close {
    right: -8px;
  }
}

.stickyCornerLeft {
  bottom: 50px;
  left: 10px;
  justify-content: flex-start;

  @media (min-width: 1024px) {
    bottom: 40px;
    left: 40px;
  }

  .close {
    left: -8px;
  }
}

.placement {
  position: relative;
  width: 135px;
  height: 135px !important;
  overflow: hidden;
  border-radius: 19999px;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      width: 450px;
    }
  }

  > iframe,
  > div {
    width: 100%;
    height: 100%;
  }
}
