.drawerRow {
  padding: 4px 8px 4px 24px;
  border: none;
  box-sizing: border-box;
  justify-content: flex-start;
  transition: 0.2s;
  cursor: pointer;
  height: 28px;

  &:hover {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.08) 0.54%, rgba(255, 255, 255, 0) 100%);
    cursor: pointer;

    &:before {
      content: '-';
      margin-left: -16px;
      font-size: 18px;
      position: absolute;
      color: rgba(255, 255, 255, 0.2);
    }
  }
  & .titleBox {
    min-width: 0;
    display: flex;


    & .symbolAction {
      display: none;
    }

    & .iconContainer {
      margin-right: 8px;
      height: 16px;
      max-height: 16px;
      max-width: 16px;
      min-width: 16px;
      position: relative;
      width: 16px;
    }

    & .symbol {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.5);
      margin-right: 5px;
    }

    & .withoutTitle {
      margin-right: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .title {
      display: flex;
      align-items: flex-end;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 12px;
      line-height: 12px;
      color: rgba(255, 255, 255, 0.2);
    }
  }
}

.titleUnderline {
  text-decoration: underline;
}
