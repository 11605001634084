.newsListGlobals {
  margin-right: 28px;
  margin-left: 16px;

  .item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 202px;
    margin-bottom: 16px;
    color: rgb(255 255 255 / 50%);
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    border-radius: 4px;

    .stable {
      color: rgb(188 178 177 / 100%);
    }

    .decrease {
      color: #dc7474;
    }

    .increase {
      color: #84c47c;
    }
  }

  .marketData {
    margin-bottom: 1px;
    padding-top: 9px;
    padding-bottom: 9px;
    background-color: #323333;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .amount {
    margin-top: 4px;
  }

  .change {
    padding-top: 9px;
    padding-bottom: 9px;
    background-color: #323333;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .currChange {
    margin-top: 4px;
  }

  .link a {
    color: inherit;
    text-decoration: none;
  }
}
