.menuItem {
  display: flex;
  flex-direction: column;
}

.mobileMenuItemBorder {
  border-bottom: 1px solid rgb(255 255 255 / 10%);
}

.mobileMenuItemSingle {
  justify-content: space-between;
  width: 100%;
  padding: 24px 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  border-bottom: 1px solid rgb(255 255 255 / 10%) !important;
  cursor: pointer;
  transition: 0.2s;
}

.separator {
  height: 1px;
  margin: 12px 0;
  border-bottom: 1px solid rgb(255 255 255 / 10%) !important;
}

.mobileMenuItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  text-transform: capitalize;
  text-decoration: none;
  background: transparent;
  border-bottom: none;
  outline: none;
  cursor: pointer;
  transition: 0.2s;

  &:active {
    outline: none;
  }

  & .childrenText {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    padding: 12px 0;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.6;
  }

  & .childrenContainer {
    display: none;
    padding: 12px 24px 24px;
  }

  & .childrenContainerOpen {
    display: inline;
  }

  & .text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    padding: 16px 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    border-bottom: 1px solid rgb(255 255 255 / 10%);
  }

  & .active {
    transform: rotateX(180deg);
  }
}
