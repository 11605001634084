.buttonGroupItem {
  height: 32px;
  padding: 0 8px;
  color: #fff;
  font-size: 14px;
  line-height: 32px;
  background-color: rgb(255 255 255 / 10%);
  cursor: pointer;

  &:hover {
    background-color: rgb(255 255 255 / 20%);
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.active {
  background-color: rgb(255 255 255 / 40%);
  cursor: default;
  pointer-events: none;

  &:hover {
    background-color: rgb(255 255 255 / 40%);
  }
}
