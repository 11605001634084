.textLinkInBottomLine {
  width: 300px;
  height: 20px;
  margin-right: 35px;

  & a {
    padding-left: 1.5em;
    color: #fff;
    font-weight: 700;
    white-space: nowrap;
    text-decoration: none;
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: 1.2em;

    &:hover {
      text-decoration: underline;
    }
  }

  iframe, > div {
    width: 100%!important;
    height: 100%!important;
  }
}

.place {
  width: 100%;
  height: 100%;
}
