.icon {
  display: flex;

  path {
    fill: rgb(255 255 255 / 40%);
  }
}

.content {
  color: white;
  line-height: 24px;
}

.toggle {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #121212;
  cursor: pointer;
  user-select: none;
  
  &:hover {
    background-color: #3e4141;
  }

  &.active {
    background-color: var(--color-primary-lightgreen);

    .icon {
      path {
        fill: #000;
      }
    }

    .content {
      color: #84c47c;
    }
  }
}
