.item {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 32px;
  white-space: nowrap;
  line-height: 32px;

  &:hover{
    background: hsla(0,0%,100%,.05);
  }

  & > span {
    width: 16px;
    height: 16px;
    left: 8px;
    position: absolute !important;
  }
}

.green {
  div:first-child {
    span {
      color: #84c47c;
    }
  }
}
