.app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1em;
  font-weight: 400;
  flex: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;

  @media (max-width: 1024px) {
    overflow: visible;
  }

  .main {
    display: flex;
    flex: 1;
    flex-flow: column nowrap;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
