.newsFeed {
  position: relative;
  z-index: 100;
  display: flex;
  flex-flow: row nowrap;
  box-sizing: border-box;
  width: 100vw;
  height: 24px;
  overflow: hidden;
  line-height: 20px;
  background-color: #1d1f1e;

  &.opened {
    z-index: 101;
    height: auto;
    padding-bottom: 0;
    background-color: #1d1f1e;
  }

  &.hide {
    height: 0;
    max-height: 0;
    padding: 0;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
  }

  span {
    transform: translateZ(0), translate3d(0, 0, 0);
    will-change: transform;
    zoom: 1;
  }

  .closer {
    position: absolute;
    right: 0;
    width: 22px;
    height: 22px;
    color: #fff;
    font-size: 55px;
    line-height: 25px;
    text-align: center;
    background-color: #333236;
    cursor: pointer;

    &.opened {
      background-color: inherit;
    }

    &::after {
      position: absolute;
      top: calc(50% - 8px);
      left: calc(50% - 5px);
      width: 12px;
      height: 12px;
      background-image: url('/assets/icons/icon-close-news.svg');
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.2;
      content: '';
    }
  }

  .opener {
    position: absolute;
    right: 22px;
    width: 22px;
    height: 22px;
    background-color: #333236;
    cursor: pointer;

    &::after {
      position: absolute;
      top: calc(50% - 8px);
      left: calc(50% - 6px);
      width: 18px;
      height: 18px;
      background-image: url('/assets/icons/icon-arrow-up.svg');
      background-repeat: no-repeat;
      background-size: 18px 18px;
      opacity: 0.2;
      content: '';
    }

    &.inverted {
      background-color: inherit;

      &::after {
        top: calc(50% - 14px);
        transform: rotate(180deg);
      }
    }
  }
}
