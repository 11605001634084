.smartInput {
  background: transparent;
  border: none;
  outline: none;
  font-size: 1rem;
  line-height: 1em;
  font-weight: 400;
  color: #e6e6e6;
  display: inline-block;
  width: 85px;
}
