.multipleEntitySelect {
  min-width: 440px;
  margin: -8px 0;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: #3c3d3d;
  }
  ::-webkit-scrollbar-thumb {
    width: 4px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
  }
}

.title {
  color: rgba(255, 255, 255, 0.5);
}

.active {
  color: #84c47c;
}
