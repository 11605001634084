.searchContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.globalSearch {
  position: absolute;
  right: 35px;
  display: flex;
  flex: 1;
  align-items: center;
}

.searchImage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: #1d1d1d;
  cursor: pointer;
}