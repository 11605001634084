.newsList {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 330px;
  overflow: auto;

  .link {
    color: inherit;
    text-decoration: none;
  }

  .item {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 28px;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid rgb(255 255 255 / 15%);
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }

  .date {
    flex-basis: 10%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .title {
    flex-basis: 72%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .name {
    flex-basis: 18%;
    overflow: hidden;
    color: #84c47c;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & > div {
    width: 99%;
  }
}
