.button {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
  width: 79px;
  height: 32px;
  padding: 4px 8px 4px 4px;
  color: #262626;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  background: var(--color-primary-lightgreen);
  cursor: pointer;
}

.login {
  margin: 0;
  color: #000;
  font-weight: 500;
}

.isMobile {
  align-items: center;
  padding: 4px;
  font-size: 14px;
}

.iframe {
  background-color: #1D1F1E;
  @media (max-width: 576px) {
    width: 100%;
  }
}

.hideIframe {
  display: none;
}

.dialogClassName {
  background: none;
}

.reset {
  position: relative;
  top: -40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  opacity: 0.8;
}

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0 0 0 / 50%);
  }


