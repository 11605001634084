.globalSearch {
  position: absolute;
  z-index: 91;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 210px;
  padding: 0 var(--space-30);
  background: var(--color-surface-700);
  background-color: #1d1d1d;
  border-bottom: none;

  // border-radius: var(--radius-sm);
}
