@use 'src/styles/utils' as *;

.tabsContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  background-color: #000;

  @media (max-width: 1245px) {
    width: 100%;
  }
}

.tabsWrapper {
  display: flex;
  gap: 2px;
  width: 100%;

  @media (max-width: 1625px) {
    max-width: 850px;
  }

  @media (max-width: 1545px) {
    max-width: 700px;
  }
  @media (max-width: 1445px) {
    max-width: 600px;
  }
  @media (max-width: 1345px) {
    max-width: 500px;
  }

  @media (max-width: 1245px) {
    max-width: 400px;
  }

  @media (max-width: 1024px) {
    max-width: none;
  }
}

.tabsWrapperMobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--surface-solid-layer-01, #1d1d1d);
  border: 1px solid var(--border-default, #3b3b3b);
  box-shadow: 8px 8px 0 0 rgb(0 0 0 / 30%);
}

.addTabMobile {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  padding: 0.5rem;
}

.addTabMobile:hover {
  cursor: pointer;
}

.addTextMobile {
  margin: 0;
  color: #7cc07c;
}

.addTab {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  padding: 0 12px;
  background-color: #000;
  cursor: pointer;
}

.addText {
  margin: 0;
  color: #6ec56e;
  font-size: 14px;
}

.tabPlaceholder {
  width: 100%;
  height: 48px;
  margin-right: 8px;
  border-radius: 4px;
  transition: opacity 0.1s ease-out;
}

.deleteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  color: #f38181;
}

.deleteButton:hover {
  cursor: pointer;
}

.confirmDeleteDialog {
  padding: 15px 20px;
}

.dialogButtons {
  display: flex;
  justify-content: space-between;

  & > button {
    display: flex;
    flex: 0.48 1;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 4px 20px 6px 15px;
    color: #e6e6e6;
    font-size: 1rem;
    line-height: 1em;
    text-align: center;
    background-color: rgb(255 255 255 / 5%);
    border-radius: 4px;
    cursor: pointer;
  }

  & > button:hover {
    background-color: rgb(255 255 255 / 10%);
  }
}

.deleteButtonMobile {
  padding: 3px 15px;
  color: #f38181;
}

.deleteButtonMobile:hover {
  cursor: pointer;
}
