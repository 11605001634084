.logo a {
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.boxText {
  position: relative;
  padding: 0;
}

.widget {
  height: 10px;
  margin-left: 5px;
}
