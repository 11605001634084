.item {
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--size-120);
  padding: var(--space-25);
  border-bottom: solid var(--size-01) var(--color-surface-600);
  cursor: pointer;
}

.title {
  margin-left: var(--space-25);
}
